<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card>
      <v-card-title>
        <span class="forth--text text-h4 font-weight-bold">
          {{ $t('messages.shiftInfoTitle') }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <span
              class="font-weight-black"
            >{{ $t('messages.customer') }}:</span>
          </v-col>
          <v-col cols="2">
            {{ customerDetail.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <span class="font-weight-black">{{ $t('messages.job') }}:</span>
          </v-col>
          <v-col cols="2">
            {{ jobDetail.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <span class="font-weight-black"> {{ $t('messages.shift') }}:</span>
          </v-col>
          <v-col cols="2">
            {{ currentShiftDetail.formatedDate }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <span class="font-weight-black">
              {{ $t('messages.availableDivisions') }}:</span>
          </v-col>
          <v-col cols="5">
            <span
              v-for="(division, index) in jobsDivisions"
              :key="index"
            >
              {{ division.name }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 "
      stle
      :loading-text="$t('table.messages.loading')"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 100],
        showFirstLastPage: false,
        'items-per-page-text': $t('table.messages.items_per_page'),
      }"
      :search="filterSearch"
      :custom-filter="filterByError"
    >
      <template slot="no-data">
        {{ $t('table.messages.no_data') }}
      </template>

      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} - {{ props.pageStop }}
        {{ $t('table.headers.page-text') }}
        {{ props.itemsLength }}
      </template>
      <template v-slot:top>
        <v-container class="mt-5">
          <v-row>
            <v-col class="pb-0">
              <div>
                <span class="forth--text text-h4 font-weight-bold ">{{
                  $t('messages.browse_file')
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex ">
            <v-col cols="10">
              <div class="align-self-center mr-9">
                <v-file-input
                  v-model="file"
                  :loading="loading"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  counter
                  show-size
                  truncate-length="29"
                  outlined
                  prepend-icon=""
                >
                  <template v-slot:counter="props">
                    <div>
                      {{
                        props.props.value.match(/\d+\.*\d*/g)[0] +
                          'ファイル （合計' +
                          props.props.value.match(/\d+\.*\d*/g)[1] +
                          'B）'
                      }}
                    </div>
                  </template>
                </v-file-input>
              </div>
            </v-col>

            <v-col
              cols="2"
              class="d-flex align-end p-0"
            >
              <div class=" mb-9">
                <v-btn
                  color="primary"
                  large
                  @click="$router.go(-1)"
                >
                  <span class="pr-5 pl-5 font-weight-bold text-h5">
                    {{ $t('table.messages.back') }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex ">
            <v-col cols="3">
              <v-select
                v-model="filterSearch"
                :items="previewOptions"
                :label="$t('messages.preview_option')"
                item-text="text"
                item-value="value"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.index="props">
        {{ props.index + 1 }}
      </template>
      <template v-slot:item.name="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'name')"
        >{{
          props.item.name
        }}</span>
      </template>
      <template v-slot:item.manageId="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'manageId')"
        >{{ props.item.manageId }}</span>
      </template>

      <template v-slot:item.dob="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'dob')"
        >{{
          props.item.dob
        }}</span>
      </template>
      <template v-slot:item.furiganaName="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'furiganaName')"
        >{{ props.item.furiganaName }}</span>
      </template>

      <template v-slot:item.language="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'language')"
        >{{ props.item.language }}</span>
      </template>
      <template v-slot:item.startEndPlan="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'startEndPlan')"
        >{{ props.item.startEndPlan }}</span>
      </template>
      <template v-slot:item.note="props">
        <span
          style="height: 100%"
          :class="setHighlight(props.item, 'note')"
        >{{
          props.item.note
        }}</span>
      </template>
      <template v-slot:footer.prepend />
    </v-data-table>
    <v-row class="d-flex justify-end mt-3">
      <v-col
        cols="3"
        class="d-flex justify-end"
      >
        <v-btn
          large
          color="info"
        >
          <span
            class="text-h5 pr-3 pl-3"
            @click="onSubmitCSV"
          >
            {{ $t('messages.importCSV') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// Ultilites
  import moment from 'moment'
  import XLSX from 'xlsx'
  import { get } from 'vuex-pathify'
  export default {
    name: 'EmployeeList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        excelFileConfig: {
          fileOffSet: 0, // defaut
          fileHeaderStart: 8,
          fileDataSkip: 0,
        },
        extraColumnKeys: [],
        allWorkTime: [],
        divisions: [],
        listOfValidEmployees: [],
        fileIsValid: false,
        rawData: [],
        previewOptions: [],
        filterSearch: '',
        headers: [],
        items: [],
        fileCriticalErrors: [],
        file: [],
        loading: false,
        schema: [],
        currentShiftDetail: {},
      }
    },
    computed: {
      ...get('parttimer', ['list', 'error']),
      ...get('job', ['jobDetail']),
      ...get('shift', ['status', 'message']),
      customerDetail: get('customer@customerDetail'),
      worktimes: get('worktime@list'),
      jobsDivisions: get('job@divisions'),
    },
    watch: {
      jobsDivisions (value) {
        value.forEach(division => {
          this.$store.dispatch('worktime/getWorktimes', {
            divisionId: division.id,
          })
        })
      },
      worktimes (value) {
        this.allWorkTime = [...this.allWorkTime, ...value]
      },
      jobDetail (value) {
        this.$store.dispatch('customer/getCustomer', {
          id: value.customerId,
        })
        this.currentShiftDetail = value.shifts.find(o => {
          return o.id + '' === this.$route.query.shiftId
        })
      },
      currentShiftDetail (value) {
        this.currentShiftDetail.formatedDate =
          moment(this.currentShiftDetail.startAt).format('HH:mm') +
          ' ~ ' +
          moment(this.currentShiftDetail.endAt).format('HH:mm')
      },
      list (value) {
        this.listOfValidEmployees = value.parttimeEmployees
      },

      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      file (value) {
        this.filterSearch = ''
        this.rawData = ''
        this.doLoadAndPaser(value)
      },
      rawData (value) {
        if (value) {
          this.items = this.doCheckAndPreview(
            value,
            this.schema,
            this.excelFileConfig.fileOffSet,
          )
        }
      },
      items (value) {
        const tempCriticalErrors = []
        this.items.forEach(item => {
          // check if there is any error for required key
          if (item.errors.find(o => o.required === true)) {
            tempCriticalErrors.push(...item.errors)
          }
        })
        this.fileCriticalErrors = tempCriticalErrors
      },
      fileCriticalErrors (value) {
        this.fileIsValid = value.length === 0
      },
      status (value) {
        this.loading = value === 'loading'
        if (value === 'success') {
          this.$router.push({
            path: '/shift-information-display',
            query: { success: true },
          })
        }
      },
      message (value) {},
    },
    mounted () {
      this.$store.dispatch('job/getJobsDivisions', {
        id: this.$route.query.jobId,
      })
      //  this.$store.dispatch('worktime/getWorktimes')
      this.$store.dispatch('job/getJobDetail', { id: this.$route.query.jobId })
      this.$store.dispatch('parttimer/getParttimers')
      // setting table's headers
      this.previewOptions = [
        { text: this.$t('messages.all'), value: 'ALL' },
        { text: this.$t('messages.only_error'), value: 'ONLY_ERROR' },
        { text: this.$t('messages.non_error'), value: 'NON_ERROR' },
        { text: this.$t('messages.critical_error'), value: 'CRITICAL_ERROR' },
      ]
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'No',
          class: 'font-weight-bold align-center',
          width: '8rem',
        },
        {
          text: this.$t('table.headers.manageId'),
          align: 'start',
          value: 'manageId',
          class: 'font-weight-bold justify-start',
          width: '8rem',
        },

        {
          text: this.$t('table.headers.name'),
          align: 'start',
          value: 'name',
          class: 'font-weight-bold',
          width: '14rem',
        },

        {
          text: this.$t('table.headers.furiganaName'),
          align: 'start',
          value: 'furiganaName',
          class: 'font-weight-bold',
          width: '14rem',
        },
        {
          text: this.$t('table.headers.language'),
          align: 'start',
          value: 'language',
          class: 'font-weight-bold',
          width: '8rem',
        },
        {
          text: this.$t('table.headers.startEndPlan'),
          align: 'start',
          value: 'startEndPlan',
          class: 'font-weight-bold justify-start',
          width: '10em',
        },
        {
          text: this.$t('table.headers.note'),
          align: 'start',
          value: 'note',
          class: 'font-weight-bold',
          width: '13rem',
        },
      ]
      this.schema = [
        {
          key: 'No',
          headerName: 'No',
          required: true,
          validate: value => {
            return /^(([\uFF00-\uFFEF]|\w)+\.*\s*)+$|^([\u4E00-\u9FAF]+\s*)+$/mu.test(
              value,
            )
          },
        },
        { key: 'name', headerName: '勤務先' },
        {
          key: 'manageId',
          unique: true,

          validate: value => {
            return !!this.listOfValidEmployees.find(o => {
              return o.manageId.toString() === value
            })
          },
          headerName: '社員番号',
          required: true,
        },
        {
          key: 'furiganaName',
          headerName: 'フリガナ',
          required: true,
          validate: value => {
            if (!value) return false
            return (
              /^([\u30A0-\u30FF]|[\uFF00-\uFFEF]|－|-|\u3000|\u0020)+$/mu.test(
                value,
              ) &&
              !!this.listOfValidEmployees.find(o => {
                return o.furiganaName === value
              })
            )
          },
        },
        {
          key: 'name',
          headerName: '氏名',
          required: true,
          validate: value => {
            return !!this.listOfValidEmployees.find(o => {
              return o.name === value
            })
          },
        },

        {
          key: 'language',
          required: true,
          headerName: '国籍',
          validate: value => {
            return !!value
          },
        },

        {
          key: 'startEndPlan',
          headerName: '出勤予定',
          required: true,
          validate: value => {
            const worktimeInput = this.parseWorktime(value)
            const worktime = this.allWorkTime.find(o => {
              return (
                worktimeInput.worktimeEndAt === o.worktimeEndAt &&
                worktimeInput.worktimeStartAt === o.worktimeStartAt
              )
            })
            return (
              /([0-9０-９]{2})(:|：)([0-9０-９]{2})～([0-9０-９]{2})(:|：)([0-9０-９]{2})/g.test(
                value,
              ) && !!worktime
            )
          },
        },
        {
          key: 'note',
          headerName: '備考',
          isNullIfError: true,
          validate: value => {
            return true
          },
        },
      ]
    },

    methods: {
      onSubmitCSV () {
        this.fileIsValid = true
        let tempData = []
        tempData = this.items.map(item => {
          // check if there is any error for required key
          if (item.errors.find(o => o.required === true)) {
            this.fileIsValid = false
          }

          // copy item to new item

          const newItem = { ...item }

          // add new porperties
          // add id
          const parttimer = this.list.parttimeEmployees.find(o => {
            return o.manageId + '' === newItem.manageId
          })
          if (parttimer) newItem.parttime_employee_id = parttimer.id
          newItem.shift_id = parseInt(this.$route.query.shiftId)
          if (!newItem.division_id || this.jobsDivisions.length === 1) {
            newItem.division_id = this.jobsDivisions[0].id
          }

          // worktime id
          const worktimeInput = this.parseWorktime(newItem.startEndPlan)
          const worktime = this.allWorkTime.find(o => {
            return (
              worktimeInput.worktimeEndAt === o.worktimeEndAt &&
              worktimeInput.worktimeStartAt === o.worktimeStartAt
            )
          })
          newItem.worktime_id = worktime.id
          newItem.isPresent = 0
          // delete unnessary complete

          const extraInfo = {}
          this.extraColumnKeys.forEach(key => {
            extraInfo[key] = newItem[key]
          })
          newItem.customInfo = JSON.stringify(extraInfo)
          // update value for error key
          this.schema.forEach(elSchema => {
            const key = elSchema.key

            if (
              item.errors.find(o => {
                return o.key === key
              })
            ) {
              if (!item[key]) newItem[key] = ''
              else if (elSchema.defautValues) {
                if (elSchema.defautValues[item[key]]) {
                  newItem[key] = elSchema.defautValues[item[key]]
                } else {
                  newItem[key] = elSchema.isEmptyIfError
                    ? ''
                    : elSchema.isNullIfError
                      ? null
                      : item[key]
                }
              }
              newItem[key] = null
            } else if (elSchema.formatValue) {
              newItem[key] = elSchema.formatValue(item[key])
            }
          })

          return {
            parttimeEmployeeId: newItem.parttime_employee_id,
            shiftId: newItem.shift_id,
            worktimeId: newItem.worktime_id,
            divisionId: newItem.division_id,
            isPresent: false,
            plannedStartAt: null,
            plannedEndAt: null,
            actualStartAt: null,
            actualEndAt: null,
            totalWorkHour: null,
            normalWorktime: null,
            nightShiftWorktime: null,
            otWorktime: null,
            breaktime: null,
            position: null,
            customInfo: newItem.customInfo,
          }
        })

        if (this.fileIsValid) {
          this.$store.dispatch('shift/importParttimerCSV', tempData)
        } else this.showSnackBar('messages.invalid_data')
      },
      // Call paser function and load to Sate rawData
      doLoadAndPaser (file) {
        if (!file) return
        // set offset
        this.excelFileConfig.fileOffSet =
          this.excelFileConfig.fileOffSet +
          this.excelFileConfig.fileDataSkip +
          this.excelFileConfig.fileHeaderStart
        this.readData(file).then(response => {
          for (var i = 0; i < this.excelFileConfig.fileDataSkip; i++) {
            response[0].shift()
          }
          // only get first sheet in File
          // required state rawData !
          this.rawData = response[0]
          this.addExtraColumns(Object.keys(response[0][1]))
        })
      },
      // check and preview the array of object through a schema
      doCheckAndPreview (data, schema, offset) {
        const items = data.map((row, index) => {
          const item = {}

          // validate and tranforming raw item
          const validate = (object, schema, rowIndex) =>
            schema
              .filter(col => {
                // transform item attribute
                const headerName = col.headerName
                const key = col.key
                item[key] = object[headerName]
                  ? object[headerName].replace(/\s+/g, ' ').trim()
                  : ''

                if (col.required && !object[headerName]) {
                  return true
                }
                if (col.unique) {
                  const testRecords = data.filter(o => {
                    return item[key] === o[headerName]
                  })
                  if (testRecords.length >= 2) return true
                }
                if (col.validate) {
                  return !col.validate(item[key], object)
                }
                return false
              })
              .map(col => {
                // set return error of item attribute
                return {
                  message: `row ${rowIndex} - ${object[col.headerName]} - '${
                    col.key
                  }'' is invalid.`,
                  key: col.key,
                  required: col.required,
                }
              })

          const errors = validate(row, schema, index + offset)

          item.errors = errors
          return item
        })

        return items
      },
      readData (fileName) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.onload = e => {
            var data = e.target.result
            let returnData = []
            var workbook = XLSX.read(data, {
              type: 'binary',
            })

            workbook.SheetNames.forEach(sheetName => {
              // Here is your object
              var xlRowObject = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetName],
                {
                  defval: '',
                  range: this.excelFileConfig.fileHeaderStart,
                  raw: false,
                },
              )
              returnData = [...returnData, xlRowObject]
            })
            resolve(returnData)
          }

          reader.onerror = function (ex) {
            reject(ex)
          }

          reader.readAsBinaryString(fileName)
        })
      },
      addExtraColumns (cols) {
        const correctCols = cols.filter(col =>
          /^[a-zA-Z0-9]+$|^[ぁ-んァ-ン一-龥]+$|^[ｧ-ﾝﾞﾟン一-龥]+$/.test(col),
        )

        correctCols.forEach((col, index) => {
          const schemaCol = this.schema.find(o => col === o.headerName)
          if (!schemaCol) {
            this.extraColumnKeys.push(col)
            const newSchemaCol = {
              key: col,
              headerName: col,
            }
            const newHeader = {
              text: col,
              align: 'start',
              value: col,
              class: 'font-weight-bold',
              width: '8rem',
            }
            this.schema.push(newSchemaCol)
            this.headers.splice(index, 0, newHeader)
          }
        })
      },
      mapOrder (array, order, key) {
        array.sort(function (a, b) {
          var A = a[key]
          var B = b[key]

          if (order.indexOf(A) > order.indexOf(B)) {
            return 1
          } else {
            return -1
          }
        })

        return array
      },
      setHighlight (item, fieldname) {
        const error = item.errors.find(o => o.key === fieldname)
        if (error) {
          if (item.errors.length === 0) return ''
          if (error.required) {
            return 'pl-2 d-flex h-100 error white--text justify-start align-center'
          }

          return 'pl-2 d-flex h-100 warning white--text justify-start align-center'
        }
        return ''
      },

      filterByError (value, search, item) {
        if (search) {
          if (search === 'ALL') return true
          if (search === 'ONLY_ERROR') return item.errors.length > 0
          if (search === 'NON_ERROR') return item.errors.length === 0
          if (search === 'CRITICAL_ERROR') {
            return item.errors.find(o => {
              return o.required === true
            })
          }
        }
      },

      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },

      parseWorktime (rawWorktime) {
        const data = rawWorktime
          .replace(/[\uff01-\uff5f]/g, function (ch) {
            return String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
          })
          .replace(/\uff1a/, ':')
        return {
          worktimeEndAt: data.split('~')[1] + ':00',
          worktimeStartAt: data.split('~')[0] + ':00',
        }
      },
    },
  }
</script>
<style lang="css">
.v-data-table {
  max-width: 100%;
}
</style>
